<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <!-- collapse  -->
        <el-collapse>
            <el-collapse-item>
                <template slot="title">
                    <div class="collapse-title px-4 items-center">
                        <div class="title">
                            狀態 : {{ info.status | accountStatus }}
                            <i class="ml-2 fas fa-chevron-down"></i>
                        </div>
                        <button class="orange-btn-800 md:text-sm text-xs h-[30px]" @click.stop="toggleMarkError">標示異常</button>
                    </div>
                </template>
                <div v-for="log in logs.slice().reverse()" :key="log.time" class="collapse-item">
                    <span>{{ log.status | accountStatusText }}：</span>
                    {{ log.time | formatDateTime }} ｜
                    {{ showUserName(log.user_type) }}
                </div>
            </el-collapse-item>
        </el-collapse>
        <!-- 標示異常區塊 -->
        <div v-if="hasMarkError" class="mark-error-area bg-white rounded-lg">
            <div class="section-title">標示異常</div>
            <div class="questions">
                <div class="question-select">
                    <label>指派處理客服</label>
                    <el-select v-model="errorDetail.assignee" placeholder="請選擇">
                        <el-option v-for="item in assigneeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </div>
                <div class="question-select">
                    <label>修改狀態</label>
                    <el-select v-model="errorDetail.status" placeholder="請選擇">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </div>
                <div class="question-area">
                    <label>異常描述</label>
                    <el-input v-model="errorDetail.description" type="textarea" :disabled="errorDetail.status !== -1" placeholder="請輸入內容" />
                </div>
                <button class="orange-btn-800-lg text-md mt-5" @click="updateStatus">更新異常資訊</button>
            </div>
        </div>
        <!-- 資訊 -->
        <div class="detail-info bg-white rounded-lg mt-4">
            <div class="salary-info">
                <!-- 金流資訊 -->
                <div class="section-content">
                    <div class="section-title">代付款項資訊</div>
                    <div class="left">
                        <div class="item">
                            <div class="section-key text-gray-medium-14">金流編號</div>
                            <div class="section-value">
                                {{ info.transaction_log_id }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="section-key">提領人</div>
                            <div class="section-value">{{ user.name }}</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="item">
                            <div class="section-key">申請時間</div>
                            <div class="section-value">
                                {{ info.created_at | formatDateTime }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="section-key">匯出 TXT</div>
                            <div class="section-value">
                                {{ info.export_time | formatDateTime }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="section-key">預計撥款時間</div>
                            <div class="section-value">
                                {{ info.transaction_date | formatDate }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 帳戶資訊 -->
                <div class="section-content">
                    <div class="section-title">帳戶資訊</div>
                    <div class="left">
                        <div class="item">
                            <div class="section-key text-gray-medium-14">銀行代碼</div>
                            <div class="section-value">
                                {{ banking.bankCode }}
                            </div>
                        </div>
                        <div class="item">
                            <div class="section-key">銀行戶名</div>
                            <div class="section-value">
                                {{ banking.accountName }}
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="item">
                            <div class="section-key">通匯代碼</div>
                            <div class="section-value">{{ banking.swift }}</div>
                        </div>
                        <div class="item">
                            <div class="section-key">銀行帳號</div>
                            <div class="section-value">
                                {{ banking.accountId }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 帳戶資訊 -->
                <div class="section-content">
                    <div class="section-title">
                        保底政策
                        <span v-if="false">（已達標)</span>
                    </div>
                    <div class="left">
                        <div class="item">
                            <div class="section-key text-gray-medium-14">保底方案</div>
                            <div class="section-value"></div>
                        </div>
                        <div class="item">
                            <div class="section-key">保底額度</div>
                            <div class="section-value"></div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="item">
                            <div class="section-key">本月營業總額</div>
                            <div class="section-value"></div>
                        </div>
                        <div class="item">
                            <div class="section-key">本月預訂時數</div>
                            <div class="section-value"></div>
                        </div>
                    </div>
                </div>
                <!-- 明細 -->
                <div class="section-content">
                    <div class="section-title">代付款項金額明細</div>
                    <div class="section-table">
                        <div class="table-tr">
                            <td>項目</td>
                            <td>訂單合計</td>
                            <td>分潤比</td>
                            <td>收益</td>
                        </div>
                        <div v-for="(item, idx) in priceDetail.sources" :key="idx" class="table-tr">
                            <td>
                                {{ idx + 1 }}. {{ item.type | orderTypes }}
                                <span class="org-link" @click="openDetail(item.dating.provider_id, item.details.datingId)">
                                    {{ item.details.datingOrderId }}
                                </span>
                            </td>
                            <td>
                                $
                                {{ (item.details.details.tip + item.details.details.serviceCharge) | formatCurrency }}
                                <!-- {{  item.details.details.price }} -->
                            </td>
                            <td>{{ item.type | orderTypesPercentage }}</td>
                            <td>$ {{ item.amount | formatCurrency }}</td>
                        </div>
                        <div class="table-tr total">
                            <td></td>
                            <td></td>
                            <td>合計</td>
                            <td>$ {{ sumOfTotal | formatCurrency }}</td>
                        </div>
                        <div class="table-tr">
                            <td></td>
                            <td></td>
                            <td>提領手續費</td>
                            <td>
                                $
                                {{ priceDetail.fee ? priceDetail.fee : 0 | formatCurrency }}
                            </td>
                        </div>
                        <div class="table-tr total">
                            <td></td>
                            <td></td>
                            <td>實際提領</td>
                            <td>
                                <span class="org-link no-line">${{ priceDetail.amount | formatCurrency }}</span>
                            </td>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button class="black-btn-outline-lg text-md mr-5" @click="backToList">返回記錄頁面</button>
                <button v-if="nextStepBtnText" class="orange-btn-800-lg text-md" @click="updateToNextStatus">
                    {{ nextStepBtnText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from "@/components/Loading.vue";

import "./filterAccount";
export default {
    name: "AccountDetail",
    components: {
        LoadingComponent,
    },
    computed: {
        // 收益合計
        sumOfTotal() {
            let result = 0;
            this.priceDetail.sources.forEach((I) => {
                result += I.amount;
            });

            return result;
        },
        // 指派客服名單
        assigneeOptions() {
            return [{ label: "無", value: 0 }];
        },
        // 金流狀態選項
        statusOptions() {
            if (this.info.reason === "CHARGE_OFF") {
                return [
                    { label: "撥款處理", value: 1 },
                    { label: "已匯出TXT", value: 2 },
                    { label: "撥款完成", value: 3 },
                    { label: "標示異常", value: -1 },
                ];
            }
            return [
                { label: "提出申請", value: 0 },
                { label: "撥款處理", value: 1 },
                { label: "已匯出TXT", value: 2 },
                { label: "撥款完成", value: 3 },
                { label: "標示異常", value: -1 },
            ];
        },
        nextStepBtnText() {
            switch (this.info.status) {
                case 0:
                    return "移至撥款處理";
                case 1:
                    return "移至撥款完成";
                case 2:
                    return "移至撥款完成";
                default:
                    return "";
            }
        },
    },
    data() {
        return {
            loading: false,
            hasMarkError: false,
            id: 0,
            // 金流資訊
            info: {
                status: 0,
                reason: "",
                transaction_log_id: 0,
                created_at: "",
                export_time: "",
                complete_time: "",
            },
            // 金額資訊
            priceDetail: {
                amount: 0, // 實際提領
                fee: 0, // 平台服務費
                sources: [], // 付款明細
            },
            logs: [], // 訂單修改紀錄
            user: {}, // 服務商資訊
            banking: {}, // 帳戶資訊

            errorDetail: {
                status: -1,
                assignee: "",
                description: "",
            },
        };
    },
    methods: {
        // 畫面顯示相關
        toggleMarkError() {
            this.hasMarkError = !this.hasMarkError;
        },
        showUserName(type) {
            switch (type) {
                case "PROVIDER":
                    return this.user.name;
                case "ADMIN":
                    return "管理者";
            }
        },
        // API 相關
        async getDetial(id) {
            const { status, data } = await this.$api
                .GetRemittanceDetail(id)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.updateDatas(data);
            }
        },
        checkStatus(val) {
            const changeStatus = val ? val : this.errorDetail.status;
            if (changeStatus === -1 && !this.errorDetail.description) {
                alert("請填寫異常描述");
                return false;
            }
            if (changeStatus === 3) {
                const opts = {
                    title: "移至撥款完成",
                    message: "要執行此操作，請在下面的表格中輸入 CONFIRM。",
                };
                const result = this.$pop.popConfirmType(opts).then(
                    () => {
                        return true;
                    },
                    () => {
                        return false;
                    }
                );
                return result;
            }
            return true;
        },
        async updateStatus() {
            // 判斷是否跳確認彈窗
            const allow = await this.checkStatus();
            if (!allow) {
                return;
            }

            this.loading = true;
            const request = { ...this.errorDetail };
            if (!this.errorDetail.description) {
                delete request.description;
            }
            const { status, data } = await this.$api
                .UpdateRemittanceStatus(this.id, request)
                .then((res) => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.updateDatas(data.remittance);
            }
        },
        updateDatas(data) {
            const { assignee, description } = data.details;
            this.errorDetail = {
                status: data.status,
                assignee,
                description,
            };
            this.logs = [...data.log];
            // 使用者
            if (data.user) {
                this.user = { ...data.user };
            }
            // 帳戶
            if (data.banking) {
                this.banking = { ...data.banking };
            }
            // 金流資訊
            this.info = {
                status: data.status,
                reason: data.reason,
                transaction_log_id: data.transaction_log_id,
                created_at: data.created_at,
                export_time: data.export_time,
                transaction_date: data.transaction_date,
            };
            // 價格資訊
            if (data.transaction_log && data.transaction_log.details) {
                this.priceDetail = {
                    ...this.priceDetail,
                    amount: data.amount, // 實際提領
                    fee: data.transaction_log.details.fee, // 平台服務費
                };
            }
            // 明細
            if (data.transaction_log && data.transaction_log.sources) {
                this.priceDetail.sources = [...data.transaction_log.sources];
            }
            // 判斷是否被標記錯誤過
            this.hasMarkError = this.logs.some((e) => e.status === "ERROR");
        },
        async updateToNextStatus() {
            // 判斷下一個狀態的Status
            const nextStatus = this.info.status === 1 ? 3 : this.info.status + 1;

            // 判斷是否跳確認彈窗
            const allow = await this.checkStatus(nextStatus);
            if (!allow) return;

            this.loading = true;
            const request = {
                status: nextStatus,
            };
            const { status, data } = await this.$api
                .UpdateRemittanceStatus(this.id, request)
                .then((res) => {
                    this.$message({
                        type: "success",
                        message: "更新成功",
                    });
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.updateDatas(data.remittance);
            }
        },
        backToList() {
            const { pre } = this.$route.query;
            const name = pre ? pre : "submit_application";
            this.$router.push({ name });
        },
        openDetail(provider_id, id) {
            window.open(`/#/${provider_id}/PaymentFlowList/provider_update/order_detail/${id}`);
        },
    },
    async mounted() {
        this.loading = true;
        const { paymentID } = this.$route.params;
        this.id = paymentID;
        this.getDetial(paymentID);
    },
    async actived() {
        this.loading = true;
        const { paymentID } = this.$route.params;
        this.getDetial(paymentID);
    },
};
</script>

<style lang="scss" scrope>
@import "@/scss/_text-style.scss";

.collapse-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.el-collapse-item__arrow {
    display: none;
}

.collapse-item {
    span {
        color: #757575;
    }
}

.el-collapse-item__content {
    padding-bottom: 10px;
}

.el-collapse-item__wrap {
    padding: 0 20px;
}

.mark-error-area {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 16% 30px 16%;
    padding-bottom: 30px;
    border-bottom: solid 1px #cbcbcb;
    .section-title {
        width: 100%;
        margin: 20px 0 10px;
        @extend .text-black-blod-18;
    }
    .questions {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        label {
            @extend .text-black-regular-14;
            font-size: 12px;
            color: #858585;
        }
        .question-select {
            width: 50%;
            padding: 0 10px;
        }
        .question-area {
            width: 100%;
            margin-top: 5px;
            padding: 0 10px;
        }
        .el-select {
            width: 100%;
            margin-top: 5px;
        }
        .el-textarea {
            width: 100%;
            margin-top: 5px;
        }
    }
}

.detail-info {
    padding: 30px 8%;
    .section-content {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 10% 30px 10%;
        padding-bottom: 30px;
        border-bottom: solid 1px #cbcbcb;
        &:last-child {
            border-bottom: none;
        }
        .section-title {
            width: 100%;
            margin-bottom: 20px;
            @extend .text-black-blod-18;
        }
        .left {
            flex: 1;
            border-right: 1px solid #cbcbcb;
        }
        .right {
            flex: 1;
            padding-left: 30px;
        }
        .item {
            display: flex;
            margin: 10px 0;
            .section-key {
                @extend .text-gray-medium-14;
                width: 150px;
            }
            .section-value {
                @extend .text-black-regular-14;
            }
        }
        .section-table {
            width: 100%;
            .table-tr {
                width: 100%;
                min-height: 40px;
                display: flex;
                .org-link {
                    color: #ff5733;
                    text-decoration: underline;
                    font-size: 14px;
                    cursor: pointer;
                    &.no-line {
                        text-decoration: none;
                    }
                }
                &:first-child {
                    background: #d1d1d1;
                }
                &.total {
                    border-top: 1px solid #cbcbcb;
                }
                td:not(:first-child) {
                    @extend .text-black-regular-14;
                    padding: 0 20px;
                    flex: 1;
                    line-height: 40px;
                    text-align: left;
                }
                td:nth-child(1) {
                    @extend .text-black-regular-14;
                    padding: 0 20px;
                    width: 40%;
                    line-height: 40px;
                    text-align: left;
                }
            }
        }
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 30px;
    }
}
</style>
